import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = makeStyles(({ breakpoints, spacing, palette }: Theme) => {
  return createStyles({
    buttonContainer: {
      '& a': {
        width: 80,
        display: 'inline-block',
        padding: spacing(),
        textAlign: 'center',
        borderRadius: spacing(),
        textDecoration: 'none',
        color: '#999',
        fontWeight: 500,
        textTransform: 'uppercase',
        '&:not(:last-of-type)': {
          marginRight: spacing(),
        },
        '&:hover': {
          color: palette.primary.contrastText,
          backgroundColor: `${palette.primary.main}a0`,
        },
      },
    },
    activeStep: {
      color: `${palette.primary.contrastText}!important`,
      backgroundColor: palette.primary.main,
    },
  });
});

export default styles;
