import { Container } from '@material-ui/core';
import * as React from 'react';
import { Header } from '..';
import { Footer } from '../../Shared';

interface Props {
  children?: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <Header />
      <Container
        style={{
          marginTop: 64 + 16,
          padding: 8,
          marginBottom: 16,
          minHeight: 540,
        }}
      >
        {children}
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
