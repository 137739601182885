import {
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'gatsby';
import * as React from 'react';
import { Layout } from '../../components/GrowthTrack';

import ArrowRightIcon from '@material-ui/icons/ArrowRightAlt';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import useStyles from '../../components/GrowthTrack/step.styles';

const Step2 = () => {
  const classes = useStyles();

  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Layout>
      <Grid
        style={{ minHeight: 540 }}
        justify="center"
        alignContent="center"
        spacing={4}
        container
      >
        <Grid xs={12} md={4} item>
          <Card className={classes.stepCard} elevation={0}>
            <CardContent>
              <Typography
                align="center"
                variant="h6"
                color="primary"
                style={{ marginBottom: 32 }}
              >
                Watch the Video
              </Typography>
              <div
                style={{
                  width: 'calc(100% - 16px)',
                  height: 200,
                  margin: '0 auto 16px auto',
                  backgroundColor: '#ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 60,
                    borderRadius: 8,
                    backgroundColor: '#f2f2f2',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <PlayArrowIcon
                    style={{ color: '#ccc', width: 48, height: 48 }}
                  />
                </div>
              </div>
              <Typography style={{ fontSize: '11pt' }}>
                Find the details of your personality, discover your gifts, and
                see how Your design reveals your purpose in life and your best
                fit in ministry.
              </Typography>
              <br />
              <Typography style={{ fontSize: '11pt' }}>
                To follow along, open or download the{' '}
                <Typography
                  component="a"
                  style={{ fontSize: '11pt' }}
                  href="/documents/growth-track-2022.pdf"
                  download="growth-track.pdf"
                >
                  Growth Track Digial Book
                </Typography>
                .
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={4} style={{ position: 'relative' }} item>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: theme.spacing(7),
              left: 0,
              padding: theme.spacing(0.5),
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              boxShadow: theme.shadows[1],
              borderRadius: theme.spacing(0.5),
            }}
          >
            <ArrowRightIcon />
          </div>
          <Card className={classes.stepCard} elevation={0}>
            <CardContent>
              <Typography
                align="center"
                variant="h6"
                color="primary"
                style={{ marginBottom: 32 }}
              >
                Take the Assessment
              </Typography>
              <Typography style={{ fontSize: '11pt' }}>
                Find the details of your personality, discover your gifts, and
                see how Your design reveals your purpose in life and your best
                fit in ministry.
              </Typography>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: 16,
                  marginBottom: 16,
                }}
              >
                <a
                  className={classes.stepLink}
                  href="/growth-track/assessment"
                  target="_blank"
                >
                  Start Assessment
                </a>
              </div>
              <Typography style={{ fontSize: '11pt' }}>
                Congratulations on discovering your spiritual gifts! Your next
                step is to learn how you can turn those gifts into purpose in
                part 2 of the video.
              </Typography>
              <br />
              <div
                style={{
                  width: 'calc(100% - 16px)',
                  height: 200,
                  margin: '0 auto 16px auto',
                  backgroundColor: '#ccc',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                }}
              >
                <div
                  style={{
                    width: 80,
                    height: 60,
                    borderRadius: 8,
                    backgroundColor: '#f2f2f2',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <PlayArrowIcon
                    style={{ color: '#ccc', width: 48, height: 48 }}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} md={4} style={{ position: 'relative' }} item>
          <div
            style={{
              display: 'flex',
              position: 'absolute',
              top: theme.spacing(7),
              left: 0,
              padding: theme.spacing(0.5),
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
              boxShadow: theme.shadows[1],
              borderRadius: theme.spacing(0.5),
            }}
          >
            <ArrowRightIcon />
          </div>
          <Card className={classes.stepCard} elevation={0}>
            <CardContent>
              <Typography
                align="center"
                variant="h6"
                color="primary"
                style={{ marginBottom: 32 }}
              >
                Completed Step 2!
              </Typography>
              <Typography style={{ fontSize: '11pt' }}>
                Thank you for attending Step Two of the Growth Track!
              </Typography>
              <br />
              <Typography style={{ fontSize: '11pt' }}>
                Complete the Step Two Connection Card, and our team will help
                you find your next step.
              </Typography>
              <Typography
                component="a"
                href="https://lifehousechurchsa.churchcenter.com/people/forms/344085"
                target="_blank"
                style={{
                  display: 'flex',
                  marginTop: 8,
                  marginBottom: 16,
                  fontSize: '11pt',
                }}
              >
                Fill in the Step Two card
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <div
        style={{
          display: 'flex',
          justifyContent: upMd ? 'flex-end' : 'center',
          marginTop: 16,
        }}
      >
        <Link className={classes.stepLink} to="/growth-track/step-3">
          Continue to Step 3 <ArrowRightIcon />
        </Link>
      </div>
    </Layout>
  );
};

export default Step2;
