import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(
  ({ breakpoints, spacing, palette, shadows }: Theme) => {
    const upMd = breakpoints.up('md');

    return createStyles({
      stepCard: {
        border: `2px solid ${palette.grey[100]}`,
        borderRadius: spacing(),
        minHeight: !upMd ? 460 : 560,
      },
      stepLink: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 250,
        padding: spacing(1.5),
        borderRadius: spacing(0.5),
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        textTransform: 'uppercase',
        fontWeight: 500,
        textDecoration: 'none',
        transition: 'background-color 250ms ease-in-out',
        alignSelf: 'flex-end',
        '&:hover': {
          backgroundColor: `${palette.primary.light}f9`,
        },
        '& svg': {
          marginLeft: spacing(),
        },
      },
    });
  }
);

export default useStyles;
