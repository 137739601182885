import {
  AppBar,
  Container,
  Grow,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Link } from 'gatsby';
import * as React from 'react';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './Header.styles';

const Header = () => {
  const classes = useStyles();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen((o) => {
      const overflow = !o ? 'hidden' : 'auto';

      document.body.style.overflow = overflow;

      return !o;
    });
  };

  const handleClose = () => {
    setOpen(false);

    document.body.style.overflow = 'auto';
  };

  return (
    <AppBar color="default" elevation={0}>
      <Container
        style={{
          minHeight: 64,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 2,
        }}
      >
        <Typography variant="h5">Growth Track</Typography>

        <div className={classes.buttonContainer}>
          {upMd ? (
            <>
              <Link
                activeClassName={classes.activeStep}
                to="/growth-track/step-1"
              >
                Step 1
              </Link>
              <Link
                activeClassName={classes.activeStep}
                to="/growth-track/step-2"
              >
                Step 2
              </Link>
              <Link
                activeClassName={classes.activeStep}
                to="/growth-track/step-3"
              >
                Step 3
              </Link>
            </>
          ) : (
            <>
              <IconButton onClick={handleOpen} color="primary">
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            </>
          )}
        </div>
        <Grow in={open}>
          <Paper
            style={{
              position: 'absolute',
              left: 0,
              top: 64,
              minHeight: 'calc(100vh - 64px)',
              width: '100%',
              backgroundColor: '#fffffff0',
              zIndex: 1,
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                display: 'flex',
                minHeight: 420,
                justifyContent: 'space-evenly',
                alignItems: 'center',
                padding: 32,
              }}
              className={classes.buttonContainer}
            >
              <Link
                onClick={handleClose}
                activeClassName={classes.activeStep}
                to="/growth-track/step-1"
              >
                Step 1
              </Link>
              <Link
                onClick={handleClose}
                activeClassName={classes.activeStep}
                to="/growth-track/step-2"
              >
                Step 2
              </Link>
              <Link
                onClick={handleClose}
                activeClassName={classes.activeStep}
                to="/growth-track/step-3"
              >
                Step 3
              </Link>
            </div>
          </Paper>
        </Grow>
      </Container>
    </AppBar>
  );
};

export default Header;
